import { initialAbility } from '@/libs/acl/config'
import router from '@/router'
import { forgetLoginData } from '@/auth/utils'
import { isDemoHost, platformUrl } from '@core/utils/utils'
import jwtDefaultConfig from './jwtDefaultConfig'

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // Get token from localStorage
        const accessToken = this.getToken()
        const storeId = this.getStoreId()
        const locale = this.getCurrentLocale() || 'ar'

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }

        // If storeId is present add it to request's X-STORE Header
        if (storeId) {
          // eslint-disable-next-line no-param-reassign
          config.headers['X-STORE'] = storeId
        }

        // Set locale
        if (locale) {
          // eslint-disable-next-line no-param-reassign
          config.headers['Accept-Language'] = locale
        }

        return config
      },
      error => Promise.reject(error),
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getStoreId() {
    return localStorage.getItem('storeId')
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  getCurrentLocale() {
    return localStorage.getItem('locale')
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  csrf() {
    return this.axiosIns.get(this.jwtConfig.csrfEndpoint)
  }

  login(...args) {
    return this.axiosIns.post(this.jwtConfig.issueTokenEndpoint, ...args, { withCredentials: true })
  }

  demoLogin(...args) {
    return this.axiosIns.get(this.jwtConfig.issueDemoTokenEndpoint, { withCredentials: true })
  }

  impersonate(token) {
    const endpoint = atob((token))
    return this.axiosIns.get(endpoint, { withCredentials: true })
  }

  forgotPassword(...args) {
    return this.axiosIns.post(this.jwtConfig.forgotPasswordEndpoint, ...args, { withCredentials: true })
  }

  resetPassword(...args) {
    return this.axiosIns.post(this.jwtConfig.resetPasswordEndpoint, ...args, { withCredentials: true })
  }

  check(axios = null) {
    return (axios || this.axiosIns).get(this.jwtConfig.homeEndpoint, { withCredentials: true })
      .then(() => {
        localStorage.removeItem('serverDown')
      })
  }

  logout() {
    console.log('logging out')
    return this.axiosIns.post(this.jwtConfig.logoutEndpoint)
  }

  logoutAndRevokeToken() {
    return this.logout().finally(() => {
      this.revokeToken()
    })
  }

  validate(...args) {
    return this.axiosIns.post(this.jwtConfig.validateEndpoint, ...args)
  }

  sendOtp(...args) {
    return this.axiosIns.post(this.jwtConfig.sendOtpEndpoint, ...args)
  }

  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args)
  }

  execute(...args) {
    return this.axiosIns.post(this.jwtConfig.executeRegistrationEndpoint, ...args)
  }

  refreshToken() {
    return this.axiosIns.post(this.jwtConfig.refreshTokenEndpoint, {
      refreshToken: this.getRefreshToken(),
    })
  }

  revokeToken() {
    // Remove userData from localStorage
    // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
    forgetLoginData([this.jwtConfig.storageTokenKeyName, this.jwtConfig.storageRefreshTokenKeyName])

    // Reset ability
    // this.$ability.update(initialAbility)
    // TODO: Re-enable
  }

  redirectToLoginPage(returnTo = null) {
    // Redirect to login page
    const route = { name: 'auth-login' }
    if (returnTo) {
      route.query = { return: returnTo }
    }
    router.push(route)
  }
}
